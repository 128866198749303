<template>
  <a-drawer class="propagate-Edit" title="修改企业宣传" @close="onClose" :width="720" :visible="propagateEditVisiable" :maskClosable="false">
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label='标题' prop="title" v-bind="formItemLayout">
        <a-input v-model="form.title" class="input-style"></a-input>
      </a-form-model-item>
      <a-form-model-item label='描述' v-bind="formItemLayout">
        <a-textarea placeholder="描述" v-model="form.desc" :rows="5" />
      </a-form-model-item>
      <a-form-model-item label='排序' v-bind="formItemLayout">
        <!-- <a-input v-model="form.sort" class="input-style"></a-input> -->
        <a-input-number v-model="form.sort" :min="1" :max="10" />
      </a-form-model-item>
      <a-form-model-item label='状态' v-bind="formItemLayout">
        <div style="text-align: left;">
          <a-tag :color="disabled?'#87d068':'red'">{{ disabled?"启用":"禁用" }}</a-tag>
          <a-switch size="small" :checked="disabled" @change="handleDisabledChange" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="图片" prop="image" v-bind="formItemLayout">
        <UploadImgs :path="IMAGE_PROPAGATE" :file-list="imageList" :limit="1" @_change="handleUploadImgChange"></UploadImgs>
      </a-form-model-item>
      <a-form-model-item label="视频" v-bind="formItemLayout">
        <UploadEnclosure :path="VEDIO_PROPAGATE" :enclosure-list="enclosureList" @_change="handleUploadEnclosureChange"></UploadEnclosure>
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import {IMAGE_PROPAGATE, VEDIO_PROPAGATE} from "../../../utils/upload-path-map"
import UploadEnclosure from "../../../components/upload-enclosure/UploadEnclosure"
import UploadImgs from "../../../components/upload-imgs/UploadImgs"

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  name: "PropagateEdit",
  components: {UploadImgs, UploadEnclosure},
  props: {
    propagateEditVisiable: {
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      IMAGE_PROPAGATE,
      VEDIO_PROPAGATE,
      enclosureList: [],
      imageList: [],
      disabled: false,
      form: {
        title: "",
        image: [],
        video: [],
        sort: "",
        status: "0",
        desc: ""
      },
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' },
          { type: "string", min:1, max: 20, message: '长度不超过20个字符', trigger: 'blur' },
        ],
        image: [
          { required: true, message: '图片不能为空', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    onClose () {
	  this.reset();
      this.$emit('close');
    },
	reset() {
	  this.$refs.ruleForm.resetFields();
	  for(let key in this.form) {
	    this.form[key] = undefined;
	  }
	  this.imageList = [];
	  this.enclosureList = [];
	},
    handleSubmit() {
      this.$put("company_propagate", {...this.form}).then(r => {
        // console.log("修改宣传成功！", r.data);
				this.reset();
        this.$emit("success");
      })
    },
    handleDisabledChange(val) {
      this.disabled = val;
      this.form.status = val?'1':'0';
    },
    setFormField({...propagate}) {
      // console.log("传过来的propagate", propagate);
      let {id, title, image, video, desc, sort, status} = propagate;
      this.disabled = status=="1"?true:false;
      this.imageList = image?[{
        uid: `-1`,
        status: 'done',
        url: `${this.$store.state.baseinfo.filedomain}${image}`,
        name: image.split('/')[image.split('/').length - 1]
      }]:[];
      this.enclosureList = video?[{
        uid: `-1`,
        status: 'done',
        url: `${this.$store.state.baseinfo.filedomain}${video}`,
        name: video.split('/')[video.split('/').length - 1]
      }]:[];
      this.form = {id, title, image, video, desc, sort, status};
    },
    handleUploadImgChange(fileList, urls) {
      console.log("上传图片组件传过来的值", urls);
      // this.form.image = JSON.stringify(urls); 
      this.form.image = urls.length?urls[0]['filePath']:'';
      this.imageList = fileList;
    },
    handleUploadEnclosureChange(list, urls) {
      console.log("上传附件的返回值===>", list, urls);
      this.enclosureList = list;
      // this.form.video = JSON.stringify(urls);
      this.form.video = urls.length?urls[0]['filePath']:'';
    }
  }
}
</script>

<style lang="less" scoped>
.propagate-Edit {
  height: calc(100% - 55px);
  overflow: auto;
  padding-bottom: 53px;
  .input-style {
    width: 100%;
  }
}
</style>